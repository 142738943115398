import React from "react";
import { useAccount } from "wagmi";

function Step02({ setTwitterUrl, warnings }) {
  const { address } = useAccount();
  const tweetContent = `Hey everyone!
I'm attending the "Arbitrum Governance and Development Initiative" by @LamprosLabsDAO!
Gaining amazing skills on @arbitrum, deploying on Arbitrum Stylus, and exploring Arbitrum Governance.
Thanks, @LamprosLabsDAO for this fantastic session!
#web3 #blockchain`;

  // const tweetContent = `Hey, I’m using the @ModeNetwork faucet to mint @Mode_Domains. You can get testnet ETH from the faucet here: https://faucet.modedomains.xyz/`;
  //   const tweetContent = "demo";
  const twitterUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
    process.env.REACT_APP_TWEET_CONTENT
  )}`;

  const handleTweetClick = () => {
    window.open(twitterUrl, "_blank");
  };
  const handleTwitterFollowClick = () => {
    window.open("https://x.com/lamproslabsdao", "_blank");
  };

  const handleTweetCheck = (e) => {
    setTwitterUrl(e.target.value);
  };

  return (
    <div className="step_1">
      <h1>Follow and share a tweet to get faucet! 🤝🏻</h1>

      {/*<div className="tweeter_parent">
        <span>Step 1:</span>
        <span onClick={handleTwitterFollowClick} className="tweet_link">
          Follow Lampros Labs DAO Twitter Account.
        </span>
      </div>*/}
      <div className="tweeter_parent">
        {/* <span>Step 2:</span> */}
        <span onClick={handleTweetClick} className="tweet_link">
          Generate the tweet for me and I'll share!
        </span>
      </div>
      <p style={{ marginTop: "30px", marginBottom: "0" }}>
        Tweet and share the URL below
      </p>
      <div className="tweet-input-div">
        <span>Tweet URL</span>
        <div className="tweet-input">
          <input
            type="text"
            placeholder="Tweeted URL"
            onChange={(e) => handleTweetCheck(e)}
          />
          {/* <button className="claim-faucet">Claim 0.1 ETH</button> */}
        </div>
      </div>
      {warnings.secondStep ? (
        <p className="error_msg">{warnings.secondStep}</p>
      ) : null}
    </div>
  );
}

export default Step02;
